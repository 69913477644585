
					$oemKey: zero;
					$oem-brand-color: #1E1E21;
					$oem-brand-color-light: #FFFFFF;
				
											$env-border-radius: 0px;
										
						$oem-font-family-stack: #{'Familjen Grotesk', sans-serif};
					
						$oem-font-family-url: "https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400..700;1,400..700&display=swap";
					
/**
 *	Styles
 */

// ekho dealer component library import
@import 'node_modules/@ekhodealer/ekho-common/dist/scss/styles';

// @import 'settings/index';
// @import 'tools/index';
// @import 'generic/index';
// @import 'elements/index';
// @import 'objects/index';
@import 'components/index';
// @import 'vendors/index';
// @import 'utilities/index';
// @import 'node_modules/bootstrap/scss/maps';
// @import 'node_modules/bootstrap/scss/utilities/api';
// @import 'custom/index';

#militaryCheckbox {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}

.pac-container {
	z-index: 1056;
}
